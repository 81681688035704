import React from "react";

import SEO from "../components/seo";

const PortalBlocked = () => (
  <>
    <SEO title="Blocked IP" />
    <p>This IP is blocked from accessing the pharmacy portal</p>
    <p>If you are currently on the pharmacy premises and believe this is incorrect, please contact support on <a href="mailto:managemymeds@avicenna.org">managemymeds@avicenna.org</a></p>
  </>
);

export default PortalBlocked;
